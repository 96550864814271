<template>
  <div class="container pb-4">
    <div class="row">
      <div class="col-md-12">
        <base-card>
          <template #header>الشروط والأحكام</template>
          <template #body>
            <h5>الشروط والاحكام</h5>
            <p>
              نحيطك علماً بأن اللوائح والقوانين التي تتضمنها هذه الاتفاقية لا
              يمكن استخدامها ضد "جو مزاد"، بل يُرجع إليها
              كاتفاقية تحكم العلاقة بين جميع الأطراف ذات العلاقة بالموقع، علماً
              بأن أي غموض في أحد اللوائح أو سوء فهم لا يجوز تفنيذه بصورة تؤثر
              سلباً على مصلحة أيٍ من الأطراف. لا يمكن اللجوء إلى أي لوائح أو
              قوانين أخرى غير اللوائح والقوانين المنصوص عليها من قبل "جو مزاد" أدناه، إلا حال وقع المدير التنفيذي للشركة على ما
              يؤكد التزامه بلوائح وقوانين أخرى، علماً بأن أي اتفاق غير موقع من
              الجهة المخولة رسمياً، سواء كان شفهياً أو خطياً، لا يلزم "جو مزاد" بأية مسؤوليات أوالتزامات قانونية. تنصح "جو مزاد" كافة المشتركين والأعضاء بقراءة اللوائح والقوانين
              التي تحكم علاقتها بعملاءها، إذ تعتبر مواصلة العملاء لعملية التسجيل
              موافقةً منهم على كل ما تتضمنه اللوائح من قوانين تشرع طبيعة العلاقة
              التي تربط الطرفين.
            </p>
            <p>
              على جميع المشتركيين فحص السيارة المراد شراؤها جيدا والتأكد من
              مطابقة الموصفات المعروضة قبل المزاودة عليها
            </p>
            <p>
              على الراغبين بالمشاركة في مزادات شراء المركبات إيداع مبلغ تأمين .
            </p>
            <p>
              يجب على كل راغب في الشراء من خلال موقعنا القيام بتسجيل بياناته
              واختيار اسم مستخدم وكلمة مرور خاصة بة حتى يتمكن من استخدام خدمات
              الموقع.
            </p>
            <p>
              يمكن للمشترك في المزاد رفع قيمة المزاودة على المركبات المطروحة
              للبيع من خلال الموقع الالكتروني.
            </p>
            <p>
              اى مزايده تتم على اى مركبة فى اخر 5 دقائق من وقت انتهاء المركبة
              سوف تؤدى لزيادة وقت المركبة بمعدل 5-1 دقائق فى كل مرة.
            </p>
            <p>
              يتم تقرير نتيجة المزاد خلال 48 ساعة من وقت انتهاء المزاد و سوف ييم
              اعلامك بنتيجة المزاد في حال كنت انت المزايد الاعلى لها عن طريق
              الايميل المسجل لدى جو مزاد.
            </p>
            <p>
              يتوجب على الفائز بإحدى المركبات المعروضة للبيع دفع قيمة المركبة/
              المركبات الخاصة به نقدا، علما بانه سيتم تحميل العميل غرامة و قدرها
              خمسة وعشرين دينار اردني عن كل يوم تاخير.في تسديد ثمن المركبة.
            </p>
            <p>
              بعد إتمامك عملية الدفع و/أو نقل الملكية سيتم تسليمك المركبة خلال
              مدة اقلها 4 ايام وعليك تسديد ثمن المركبه خلال ثلاثة ايام عمل من
              يوم انتهاء المزاد والا سوف تفقد حقك في المزاد وسيتم مصادرة مبلغ
              التأمين الخاص بك
            </p>
            <p>
              سوف تتم مصادرة مبلغ التأمين حال قام صاحبه بخرق أو مخالفة شروط
              وقوانين استخدام الموقع.
            </p>
            <p>
              يمكن للأعضاء امتلاك عدد غير محدود من المركبات المطروحة في المزاد.
            </p>
            <p>
              يعود حق استخدام كافة الأسماء والعلامات التجارية وأسماء وعلامات
              الخدمات وأسماء المقتنيات والشعارات والصور لأصحاب الموقع وحدهم.
            </p>
            <p>
              حكم هذه الوثيقة كافة جوانب اتفاقية التسجيل المبرمة بين مختلف
              الأطراف تتعهد كعضو في الموقع بالالتزام بالتعليمات الواردة أدناه
              عنداستخدام الموقع الخاص بـ "جو مزاد": عدم انتهاك
              أية قوانين أو حقوق أواتفاقيات أي طرف من الأطراف. عدم استخدام
              الموقع حال لم تملك الصلاحية القانونية للمزايدة، أو حال لم تبلغ الـ
              18 من العمر، أو حال تم منعك من استخدام الموقع سواءًكان ذلك بصورة
              مؤقتة أو نهائية.
            </p>
            <p>اتمام عملية دفع قيمة المركبات التي قمت بشراءها.</p>
            <p>
              عدم التلاعب بما يخص المزاد من أسعار أو لوائح، والالتزام بعدم
              التحايل على سيرالمزادات.
            </p>
            <p>
              عدم نشر معلومات مغلوطة أو غير صحيحة أو مضللة أو من شأنها طعن أو
              تشويه سمعة أي طرف من الأطراف. عدم إعطاء اسم المستخدم الخاص بك لدى
              " جو مزاد " لأي طرف من الأطراف دون اذن مسبق.
            </p>
            <p>
              عدم إزعاج روّاد الموقع بإرسال مواد من شأنها إزعاج أو الإضرار أو
              التجسس على أي طرف من الأطراف. عدم إرسال أي رسائل أو مواد ملفقة على
              لسان "جو مزاد".
            </p>
            <p>
              تملك "جو مزاد" حق تحديد أوإيقاف أو إنهاء أي من
              الخدمات التي يوفرها الموقع، كما يحق لها منع المستخدمين من دخول
              الموقع أو إزالة أي مواد منشورة عليه، أو اتخاذ اجراءات الكترونية أو
              قانونية لمنع المستخدمين من دخول الموقع دون سابق إنذار، وذلك حال
              وجدت إدارة "جو مزاد" ضرورة اتخاذ أيٍ من الإجراءات
              الواردة أعلاه لأي سبب من الأسباب، علماً بأن الشركة تمتلك الحق
              بإلغاء الحسابات غير المفعّلة أو الحسابات غير النشطة عندالحاجة
              لذلك.
            </p>
            <p>
              يحق للـ "جو مزاد" تغيير رسوم الخدمات التي يقدمها
              الموقع بصورة مؤقتة، وذلك لأسباب ترويجية أو عند طرح خدمات جديدة.
            </p>
            <p>
              تتعهد كمستخدم للموقع بعدم استخدام robot, spider, scraper other
              automated means للدخول إلى robot exclusion header's يحتويها الموقع
              دون الحصول على إذن مسبق لأي سبب من الأسباب.
            </p>
            <h5>علاوة على ما سبق، تتعهد بعدم:</h5>
            <p>
              القيام بأي نشاط من شأنه أن يؤثر سلباً على البنية التحتية للموقع.
              التأثير أو محاولة التأثير على عمل الموقع أو أي نشاطٍ من نشاطاته.
            </p>
            <p>
              منع مستخدمي الموقع من الوصول إليه، أو قصر عملية الوصول إليه على
              فئة معينة بأي طريقة كانت.
            </p>
            <p>
              <span>يمكنك إرسال الشكاوي أو التواصل معنا من خلال </span
              >&nbsp;<router-link :to="{ name: 'contact' }"
                >إتصل بنا</router-link
              >
            </p>
            <h5>الضمان والمسؤولية القانونية</h5>
            <p>
              لا تضمن جو مزاد تطابق المركبات المعروضة للبيع في
              مزاداتها بالمواصفات الواردة على الموقع الإلكتروني، علمآ بأنها لا
              تضمن أي جوانب أخرى تتعلق بالمركبات المعروضة سواء تعلقت بصلاحية
              استخدامها أو بقيمتها التجارية أو حالة المركبات.
            </p>
            <h5>سياسة الاسترجاع</h5>
            <p>
              يتوجب على الفائز باحدى المركبات دفع المبالغ المترتبة عليه بعد
              انتهاءالمزاد، علماً بأنه لن يتمكن من استرجاع أيٍ من المبالغ التي
              قام بدفعها في حال فشل في سداد ما تبقى عليه من مبالغ، إذ لا
              يجوزاسترجاع النقود مقابل إرجاع المركبات المشتراة.
            </p>
            <h5>سياسة خصوصية العملاء</h5>
            <p>
              تخضع كافة معلوماتك لدى"جو مزاد" للخصوصية، إذا
              نقوم بجمع معلومات مستخدمي الموقع من خلال أسماءالأعضاء المشتركين
              فقط، كما يتم جمع الاحصائيات الخاصة بزوار الموقع بصورة عشوائية تحفظ
              للمستخدمين خصوصية معلوماتهم.
            </p>
          </template>
        </base-card>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCard from "@/components/UI/BaseCard";
export default {
  components: { BaseCard },
};
</script>

<style scoped>
* {
  text-align: justify;
}

h5,
h6 {
  color: #f44336;
}
</style>
